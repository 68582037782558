import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import LeftSidebar from '../components/potree/project/LeftSidebar';
import RightSidebar from '../components/potree/project/RightSidebar';
import TabsBar from '../components/potree/project/TabsBar';
import Footer from '../components/potree/shared/Footer';
import { SidebarProvider } from '../components/shadcn-ui/sidebar';
import { useTheme } from '../components/shared/ThemeProvider';
import { DEFAULTS } from '../constants/defaults';
import { useToggle } from '../hooks/useToggle';

const PotreeLayout: React.FC = () => {
  // Set the correct theme for this layout (enforced by potree)
  const { potreeDisplayMode } = useToggle();
  const themeProvider = useTheme();

  useEffect(() => {
    const theme =
      potreeDisplayMode === DEFAULTS.potreeDisplayTab ? 'light' : 'dark';
    themeProvider.setTheme(theme);
  }, [themeProvider, potreeDisplayMode]);

  return (
    <>
      {/* NOTE: old sidebar, not shadcn */}
      <LeftSidebar />

      <main className="project-layout flex h-screen w-full items-center justify-center">
        <Outlet />
      </main>

      {/* NOTE: SidebarProvider is a necessary wrapper for shadcn sidebars */}
      <SidebarProvider>
        {/* NOTE: shadcn collapsible sidebar */}
        <RightSidebar />

        {/* NOTE: contains trigger for collapsing shadcn sidebar */}
        <TabsBar />
      </SidebarProvider>

      <Footer />
    </>
  );
};

export default PotreeLayout;
