import { useContext } from 'react';

import { ConfirmationContext } from '../context/ConfirmationProvider';

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);

  if (!context) {
    throw new Error(
      'useConfirmation must be used within an ConfirmationContext'
    );
  }

  return context;
};
