import JSON5 from 'json5';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../components/shared/Button';
import Error from '../components/shared/Error';
import Input from '../components/shared/Input';
import Label from '../components/shared/Label';
import Loading from '../components/shared/Loading';
import Textarea from '../components/shared/Textarea';
import useApi from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { FormFieldError, Project, UserInfo } from '../types';
import { authTokenHeader, isEmpty } from '../utils/helper';

const EditProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();
  const { i18n, t } = useTranslation();
  const locale = new Intl.Locale(i18n.language);

  const [project, setProject] = useState<Project | null>(null);
  const [errors, setErrors] = useState<FormFieldError>({});
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  // Handle JSON5 as string
  const [json5, setJson5] = useState<string>('');
  const handleJSON5Change = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setJson5(e.target.value);

  const {
    data: projectResult,
    loading: projectLoading,
    error: projectError,
  } = useApi<Project | null>({
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${id}`,
    headers: authTokenHeader(token),
  });

  useEffect(() => {
    if (projectResult && !isEmpty(projectResult) && id && !isEmpty(id))
      setProject(projectResult);
    setJson5(JSON5.stringify(projectResult?.field_json5, { space: 4 }));
  }, [projectResult, projectLoading, projectError, id]);

  // Empty errors whenever project input value update
  useEffect(() => setErrors({}), [setProject]);

  // Check what roles the user has, e.g. whether the user is an admin
  useEffect(() => {
    const getUserInfo = async () => {
      const getUserResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/info`,
        {
          method: 'POST',
          headers: authTokenHeader(token),
        }
      );
      const info: UserInfo = await getUserResponse.json();
      setUserInfo(info);
    };
    getUserInfo();
  }, [token]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setProject((prev) => ({
      ...(prev as Project),
      [e.target.name]: e.target.value,
    }));

  const updateProject = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${id}`,
        {
          method: 'POST',
          headers: authTokenHeader(token),
          body: JSON.stringify(project),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        toast.success('Project updated successfully.');
        return;
      }

      toast.error('Project update failed.');
    } catch (error: any) {
      console.log('API request failed:', error.message);
      throw Error(error.message);
    }
  }, [project, token, id]);

  const onEditProjectFormSubmit = () => {
    // Add the PARSED json5 object to project
    setProject((prev) => ({
      ...(prev as Project),
      field_json5: JSON5.parse(json5),
    }));

    updateProject();
  };

  return (
    <section className="mt-10 overflow-x-auto">
      {projectLoading ? (
        <Loading classNames="flex justify-center" theme="dark" />
      ) : (
        <>
          {projectError && (
            <Error message={projectError.message} classNames="!p-0" />
          )}

          {!project ? (
            <Error message={`Project ${id} not found!`} classNames="!p-0" />
          ) : (
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-[50fr_40fr_10fr] gap-4">
                  <div className="flex flex-col gap-2">
                    <Label text={t('project')} classNames="text-sm-regular" />
                    <Input
                      name="label"
                      value={project.label}
                      placeholder={t('project')}
                      onInputChange={handleInputChange}
                      error={errors.label}
                      width="full"
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <Label text={t('created')} classNames="text-sm-regular" />
                    <Input
                      name="created"
                      value={new Date(
                        parseInt(project.created) * 1000
                      ).toLocaleString(locale)}
                      placeholder={t('created')}
                      onInputChange={() => {}} // Date created should not be editable, I guess...
                      error={errors.created}
                      width="full"
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <Label text="ID" classNames="text-sm-regular" />
                    <Input
                      name="id"
                      value={project.id}
                      placeholder="ID"
                      onInputChange={() => {}} // Internal ID should not be editable, I guess...
                      error={errors.id}
                      width="full"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Label text={t('location')} classNames="text-sm-regular" />
                  <Input
                    name="field_location"
                    value={project.field_location}
                    placeholder={t('location')}
                    onInputChange={handleInputChange}
                    error={errors.field_location}
                    width="full"
                  />
                </div>

                {userInfo?.roles.includes('administrator') && (
                  <div className="flex flex-col gap-2">
                    <Label
                      text={t('edit_project_field_json5')}
                      classNames="text-sm-regular"
                    />
                    <Textarea
                      name="irrelevant..."
                      value={json5}
                      placeholder="JSON5"
                      onInputChange={handleJSON5Change}
                      error={errors.field_json5}
                    />
                  </div>
                )}

                <div className="flex flex-row gap-6">
                  <Button
                    label="cancel"
                    handleClick={() => navigate('/dashboard')}
                    type="submit"
                    classNames="bg-accent"
                  />

                  <Button
                    label="update"
                    handleClick={onEditProjectFormSubmit}
                    type="submit"
                    classNames="bg-accent"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default EditProject;
