import { useEffect } from 'react';
import { toast } from 'react-toastify';

import ProjectsList from '../components/admin/ProjectsList';
import Error from '../components/shared/Error';
import Loading from '../components/shared/Loading';
import useApi from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { Project } from '../types';
import { authTokenHeader } from '../utils/helper';

const Dashboard = () => {
  const { token } = useAuth();

  const {
    data: projects,
    loading,
    error,
  } = useApi<Project[] | null>({
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/projects`,
    headers: authTokenHeader(token),
  });

  useEffect(() => {
    toast.error(error?.message);
  }, [error]);

  return (
    <section className="mt-10 overflow-x-auto">
      {loading ? (
        <Loading classNames="flex justify-center" theme="dark" />
      ) : (
        <>
          {error ? (
            <Error message={error.message} classNames="!p-0" />
          ) : (
            <ProjectsList projects={projects as Project[]} />
          )}
        </>
      )}
    </section>
  );
};

export default Dashboard;
