import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Error from './components/shared/Error';
import ProtectedRoute from './components/shared/ProtectedRoute';
import { ThemeProvider } from './components/shared/ThemeProvider';
// Context providers
import { AuthProvider } from './context/AuthProvider';
import { ConfirmationProvider } from './context/ConfirmationProvider';
import { PotreeProvider } from './context/PotreeProvider';
import { ToggleProvider } from './context/ToggleProvider';
import './globals.css';
// Layouts
import AdminLayout from './layouts/AdminLayout';
import PotreeLayout from './layouts/ProjectLayout';
import AddProject from './pages/AddProject';
import Dashboard from './pages/Dashboard';
import EditProject from './pages/EditProject';
// Pages
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Project from './pages/Project';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<Home />} />

        <Route
          element={<ProtectedRoute />}
          errorElement={<Error message="Something went wrong." />}
        >
          <Route
            path="dashboard"
            element={<Dashboard />}
            errorElement={
              <Error message="Something went wrong to fetch projects data." />
            }
          />

          <Route path="project">
            <Route path="add" element={<AddProject />} />
            <Route
              path=":id/edit"
              element={<EditProject />}
              errorElement={
                <Error message="Something went wrong to fetch project data." />
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="project">
        <Route
          element={
            <PotreeProvider>
              <ToggleProvider>
                <PotreeLayout />
              </ToggleProvider>
            </PotreeProvider>
          }
        >
          <Route path=":projectId" element={<Project />} />
          <Route
            path=":projectId/present/:presentationId"
            element={<Project />}
          />
        </Route>
      </Route>
    </>
  )
);

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider defaultTheme="light" storageKey="orto-app-ui-theme">
        <ToastContainer position="bottom-left" />

        <ConfirmationProvider>
          <RouterProvider router={router} />
        </ConfirmationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
