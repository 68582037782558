import { Outlet } from 'react-router-dom';

import AdminHeader from '../components/admin/AdminHeader';
import Footer from '../components/admin/Footer';
import Container from '../components/shared/Container';
import { useTheme } from '../components/shared/ThemeProvider';

const AdminLayout: React.FC = () => {
  // Per default force a dark theme on this layout
  const theme = useTheme();
  theme.setTheme('dark');

  return (
    <div className="min-w-screen flex min-h-screen flex-col text-sm-regular">
      <AdminHeader />
      <main className="flex-grow">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default AdminLayout;
