interface ErrorProps {
  message: string;
  classNames?: string;
}

const Error: React.FC<ErrorProps> = ({ message, classNames }) => (
  <p
    className={`h-5 px-4 text-sm-regular text-destructive ${classNames ?? ''}`}
  >
    {message ?? null}
  </p>
);

export default Error;
