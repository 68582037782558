import { DefaultOptions, Theme } from '../../types';
import Error from './Error';

interface RadioItemProps {
  theme?: Theme;
  label: string;
  value: string | number;
  checked: boolean;
  handleRadioItemChange: (value: string | number) => void;
}

const RadioItem = ({
  label,
  value,
  checked,
  handleRadioItemChange,
  theme,
}: RadioItemProps) => (
  <label className="flex cursor-pointer flex-row items-center gap-2">
    <input
      type="radio"
      id={value.toString()}
      value={label}
      checked={checked}
      onChange={() => handleRadioItemChange(value)}
      className={`h-4 w-4 cursor-pointer appearance-none rounded-full focus:outline-none ${
        theme === 'dark'
          ? 'bg-white checked:bg-secondary'
          : 'bg-accent checked:bg-primary'
      }`}
    />
    {label}
  </label>
);

interface RadioProps {
  theme?: Theme;
  fieldLabel: string;
  defaultOptions: DefaultOptions;
  selectedValue: string;
  handleChange: (value: string | number) => void;
  error?: string;
}

const Radio: React.FC<RadioProps> = ({
  fieldLabel,
  defaultOptions,
  selectedValue,
  handleChange,
  error,
  theme = 'dark',
}) => (
  <div className="relative flex flex-col gap-1">
    <div className="flex flex-col gap-4">
      <label
        className={`text-base-regular ${
          theme === 'dark' ? 'text-secondary' : 'text-primary'
        }`}
      >
        {fieldLabel}
      </label>

      {defaultOptions.map(({ label, value }) => (
        <RadioItem
          key={value}
          label={label}
          value={value}
          checked={selectedValue === value}
          handleRadioItemChange={() => handleChange(value)}
          theme={theme}
        />
      ))}
    </div>
    <Error message={error ?? ''} />
  </div>
);

export default Radio;
