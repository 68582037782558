import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { GrDown, GrUp } from 'react-icons/gr';

import { cn } from '../../../utils/helper';

interface AccordionProps {
  label: string;
  body: ReactNode;
  defaultOpen?: boolean;
  classNames?: string;
  headerClassNames?: string;
  labelColor?: string;
  openAccordionIcon?: IconType;
  closeAccordionIcon?: IconType;
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  body,
  classNames,
  headerClassNames,
  defaultOpen = false,
  labelColor,
  openAccordionIcon: IconOpen,
  closeAccordionIcon: IconClose,
}) => {
  const { t } = useTranslation();
  const [isAccordionOpen, setIsAccordionOpen] = useState(defaultOpen);

  const open = IconOpen ? (
    <IconOpen size={24} className={cn(labelColor)} />
  ) : (
    <GrUp size={24} className={cn(labelColor)} />
  );
  const close = IconClose ? (
    <IconClose size={24} className={cn(labelColor)} />
  ) : (
    <GrDown size={24} className={cn(labelColor)} />
  );

  return (
    <section className={cn('flex flex-col gap-4 text-primary', classNames)}>
      {/* Accordion Header */}
      <div
        className={cn(
          'flex cursor-pointer items-center justify-between',
          headerClassNames
        )}
        onClick={() => setIsAccordionOpen(() => !isAccordionOpen)}
      >
        <p className={cn('text-base-regular capitalize', labelColor)}>
          {t(`${label}`)}
        </p>
        <div>{isAccordionOpen ? open : close}</div>
      </div>

      {/* Accordion Body */}
      <div
        className={cn(
          'transition-all duration-300 ease-in-out',
          isAccordionOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'
        )}
      >
        <div>{body}</div>
      </div>
    </section>
  );
};

export default Accordion;
