import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../../hooks/useAuth';
import { usePotree } from '../../../hooks/usePotree';
import { useToggle } from '../../../hooks/useToggle';
import { authTokenHeader } from '../../../utils/helper';
import Button from '../../shared/Button';
import Label from '../../shared/Label';
import Input from '../shared/Input';
import Textarea from '../shared/Textarea';

const AddNote = () => {
  const { token } = useAuth();
  const { project, setProject } = usePotree();
  const { setIsOpenSubmenu, setEditNoteFormId } = useToggle();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [newNote, setNewNote] = useState({
    subject: '',
    value: '',
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrors({});
    setNewNote((prevNote) => ({
      ...prevNote,
      [e.target.name]: e.target.value,
    }));
  };

  const addNote = async () => {
    const body: any = JSON.stringify({
      subject: newNote.subject,
      comment_body: {
        value: newNote.value,
        format: 'plain_text',
      },
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${project?.id}/note`,
        {
          method: 'PUT',
          headers: authTokenHeader(token),
          body,
        }
      );

      const newNote = await response.json();

      if (newNote && project) {
        console.log({ newNote });

        toast.success('New note added successfully.');

        setProject((prevProject: any) => ({
          ...prevProject,
          field_notes: [...prevProject.field_notes, newNote],
        }));

        return;
      }

      toast.error('Adding new note failed.');
    } catch (error: any) {
      console.log('API request failed:', error.message);
      throw Error(error.message);
    }
  };

  const handleAddNote = () => {
    if (!newNote.subject.trim()) {
      setErrors((prev) => ({
        ...prev,
        subject: 'Note subject is required.',
      }));

      return;
    }

    if (!newNote.value.trim()) {
      setErrors((prev) => ({
        ...prev,
        value: 'Note description is required.',
      }));

      return;
    }

    addNote();
    setIsOpenSubmenu(false);
    setEditNoteFormId(null);
  };

  return (
    <div className="flex h-full flex-col justify-between gap-4">
      <Label text={`Add Note: ${newNote.subject}`} />
      <div className="flex h-full flex-col justify-between gap-8 bg-white">
        <div className="flex flex-col gap-4">
          <Input
            name="subject"
            value={newNote.subject}
            handleInputChange={handleInputChange}
            error={errors.subject}
          />

          <Textarea
            name="value"
            value={newNote.value}
            handleInputChange={handleInputChange}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button
            label="create"
            handleClick={handleAddNote}
            classNames="text-white"
          />
          <Button
            label="cancel"
            handleClick={() => setIsOpenSubmenu(false)}
            classNames="text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default AddNote;
