import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../../hooks/useAuth';
import { usePotree } from '../../../hooks/usePotree';
import { useToggle } from '../../../hooks/useToggle';
import { Note } from '../../../types';
import { authTokenHeader } from '../../../utils/helper';
import Button from '../../shared/Button';
import Label from '../../shared/Label';
import Input from '../shared/Input';
import Textarea from '../shared/Textarea';

const EditNote = ({ note }: { note: Note }) => {
  const { token } = useAuth();
  const { setProject } = usePotree();
  const { setIsOpenSubmenu, setEditNoteFormId } = useToggle();

  const [editedNote, setEditedNote] = useState<Note>({ ...note });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrors({});

    if (e.target.name === 'subject') {
      setEditedNote((prevNote) => ({
        ...prevNote,
        subject: e.target.value,
      }));
    }

    if (e.target.name === 'value') {
      setEditedNote((prevNote) => ({
        ...prevNote,
        comment_body: {
          ...prevNote.comment_body,
          value: e.target.value,
        },
      }));
    }
  };

  const updateNote = async () => {
    const body: any = JSON.stringify({
      subject: editedNote.subject,
      comment_body: {
        value: editedNote.comment_body.value,
        format: 'plain_text',
      },
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/note/${editedNote.cid}`,
        {
          method: 'POST',
          headers: authTokenHeader(token),
          body,
        }
      );

      const updatedNote = await response.json();

      if (updatedNote) {
        const updatedNoteId = updatedNote.cid;

        toast.success('Note updated successfully.');

        setProject((prevProject: any) => ({
          ...prevProject,
          field_notes: prevProject.field_notes.map((item: Note) =>
            item.cid === updatedNoteId ? updatedNote : item
          ),
        }));

        return;
      }

      toast.error('Updating note failed.');
    } catch (error: any) {
      console.log('API request failed:', error.message);
      throw Error(error.message);
    }
  };

  const handleUpdateNote = () => {
    if (!editedNote.subject.trim()) {
      setErrors((prev) => ({
        ...prev,
        subject: 'Note title is required.',
      }));

      return;
    }

    updateNote();
    setIsOpenSubmenu(false);
    setEditNoteFormId(null);
  };

  return (
    <div className="flex h-full flex-col justify-between gap-4">
      <Label text={`Edit: ${editedNote.subject}`} />
      <div className="flex h-full flex-col justify-between gap-8 bg-white">
        <div className="flex flex-col gap-4">
          <Input
            name="subject"
            value={editedNote.subject}
            handleInputChange={handleInputChange}
            error={errors.title}
          />

          <Textarea
            name="value"
            value={editedNote.comment_body.value}
            handleInputChange={handleInputChange}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button
            label="save"
            handleClick={handleUpdateNote}
            classNames="text-white"
          />
          <Button
            label="cancel"
            handleClick={() => setIsOpenSubmenu(false)}
            classNames="text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default EditNote;
