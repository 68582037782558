import { Link } from 'react-router-dom';

import OrtoLogo from '../../shared/OrtoLogo';

const Footer = () => {
  return (
    <footer className="absolute bottom-6 left-6 z-40">
      <Link to="/">
        <OrtoLogo />
      </Link>
    </footer>
  );
};

export default Footer;
