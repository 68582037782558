import { ChangeEvent } from 'react';

import { DefaultOptions } from '../../types';
import Error from './Error';

interface CheckboxItemProps {
  name: string;
  label: string;
  value: string | number;
  checked: boolean;
  handleCheckboxItemChange: (
    e: ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => void;
}

const CheckboxItem = ({
  name,
  label,
  value,
  checked,
  handleCheckboxItemChange,
}: CheckboxItemProps) => (
  <label className="flex cursor-pointer flex-row items-center gap-2">
    <input
      type="checkbox"
      name={name}
      id={value.toString()}
      value={label}
      checked={checked}
      onChange={(e) => handleCheckboxItemChange(e, value)}
      className={
        'h-4 w-4 cursor-pointer appearance-none rounded-sm bg-secondary-foreground checked:bg-primary focus:outline-none'
      }
    />
    {label}
  </label>
);

interface MultiCheckboxProps {
  fieldLabel?: string;
  fieldName: string;
  defaultOptions: DefaultOptions;
  checkedValues: (string | number)[];
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => void;
  error?: string;
}

const MultiCheckbox: React.FC<MultiCheckboxProps> = ({
  fieldLabel,
  fieldName,
  defaultOptions,
  checkedValues,
  handleChange,
  error,
}) => (
  <div className="relative flex flex-col gap-1">
    <div className="flex flex-col gap-4">
      <label className={'ext-secondary text-base-regular'}>{fieldLabel}</label>
      {defaultOptions.map(({ label, value }) => (
        <CheckboxItem
          name={fieldName}
          key={value}
          label={label}
          value={value}
          checked={checkedValues.includes(value)}
          handleCheckboxItemChange={(e) => handleChange(e, value)}
        />
      ))}
    </div>
    {error && <Error message={error} />}
  </div>
);

export default MultiCheckbox;
