import { ChangeEvent } from 'react';

import Error from './Error';

interface TextareaProps {
  name: string;
  value: string;
  onInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string | null;
  placeholder?: string;
  rows?: number;
  cols?: number;
  classNames?: string;
}
const Textarea: React.FC<TextareaProps> = ({
  name,
  value,
  onInputChange,
  error,
  placeholder,
  rows = 12,
  cols = 40,
  classNames,
}) => {
  return (
    <div className="relative flex flex-col gap-1">
      <textarea
        name={name}
        value={value}
        onChange={(e) => onInputChange(e)}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        className={`rounded-lg bg-accent p-4 outline-none ${classNames ?? ''} `}
      />
      <Error message={error ?? ''} />
    </div>
  );
};

export default Textarea;
