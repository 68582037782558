import { useTranslation } from 'react-i18next';

const Heading = ({
  title,
  classNames,
}: {
  title: string;
  classNames?: string;
}) => {
  const { t } = useTranslation();

  return (
    <p className={`text-lg-regular capitalize ${classNames ?? ''}`}>
      {t(`${title}`)}
    </p>
  );
};

export default Heading;
